import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';

import { addMessage } from '../../state/actions/messagesActions';
import { addNotification } from '../../state/actions/notificationsActions';
import { addWishlist, updateWishlist } from '../../state/actions/wishlistsActions';

import WishlistEventNotification from './WishlistEventNotification';
import { Loader } from '../shared/Loader';

import { ApplicationState } from '../../types/state/storeTypes';
import { ExitIcon } from '../../modules/Icons';

const CreateOrUpdateWishlistForm: React.FC<any> = (props) => {
  // default mode is Create Wishlist
  const {
    user,
    action = 'create',
    wishlist = null,
    completedCallback = (wishlist) => null,
    buttonText,
    onClose
  } = props;

  const dispatch = useDispatch();

  const [wishlistName, setWishlistName] = useState('');
  const [displayInvalidUi, setDisplayInvalidUi] = useState<boolean>(false);
  const [busyLoading, setBusyLoading] = useState(false);

  function onSubmit() {
    if (!wishlistName?.length) {
      dispatch(
        addMessage({
          type: 'warning',
          body: 'Please provide the name of your Wishlist!'
        })
      );
      setDisplayInvalidUi(true);
      return;
    }
    switch (action) {
      case 'update':
        return handleUpdate();
      case 'create':
      default:
        return handleAdd();
    }
  }

  function handleUpdate() {
    setBusyLoading(true);
    dispatch({
      type: '',
      api: {
        method: 'PUT',
        url: `/wishlists/${wishlist.id}`,
        data: { name: wishlistName },
        headers: { authorization: user.token },
        onError: (res) => {
          dispatch(
            addMessage({
              type: 'error',
              body: 'Something went wrong saving the wishlist. Please try again later.'
            })
          );
          setBusyLoading(false);
          return false;
        },
        onSuccess: (res) => {
          const updatedWishlist = {
            ...wishlist,
            name: wishlistName
          };
          dispatch(updateWishlist(updatedWishlist));
          dispatch(
            addNotification({
              body: (
                <WishlistEventNotification wishlist={updatedWishlist} eventType="updateWishlist" />
              )
            })
          );
          completedCallback(updatedWishlist);
          setBusyLoading(false);
        }
      }
    });
    return false;
  }

  function handleAdd() {
    setBusyLoading(true);
    dispatch({
      type: '',
      api: {
        method: 'POST',
        url: `/wishlists`,
        data: { name: !wishlistName ? 'Favorites' : wishlistName },
        headers: { authorization: user.token },
        onError: (res) => {
          dispatch(
            addMessage({
              type: 'error',
              body: 'Something went wrong saving to wishlist. Please try again later.'
            })
          );
          setBusyLoading(false);
          return false;
        },
        onSuccess: (res) => {
          const wishlist = res.data.wishlist;
          dispatch(addWishlist(wishlist));
          dispatch(
            addNotification({
              body: <WishlistEventNotification wishlist={wishlist} eventType="createWishlist" />
            })
          );
          completedCallback(wishlist);
          setBusyLoading(false);
        }
      }
    });
    return false;
  }

  return (
    <>
      <div className="p-6 rounded-[3px] bg-[#252B2D] max-w-[553px] !z-[99] drop-shadow-lg !leading-normal">
        <div className="flex items-center justify-between text-white leading-[28px] text-[21px] mb-2 md:mb-6">
          {action === 'create' ? 'New' : 'Rename'} Wishlist
          <ExitIcon
            className="cursor-pointer stroke-white !w-[24] !h-[24px]"
            onClick={() => onClose()}
          />
        </div>
        <p className="text-white font-light mb-6">
          Wishlists allow you to collect and save assets for yourself and others.
        </p>
        <input
          type="text"
          onChange={(e) => setWishlistName(e.target.value)}
          onKeyDown={(e) => {
            // when component within Menu, disable Menu keyboard interaction to make room for typing in wishlist name
            e.stopPropagation();
            // create wishlist on Enter
            if (e.key === 'Enter') {
              onSubmit();
            }
          }}
          onFocus={() => setDisplayInvalidUi(false)}
          className={`w-full border bg-[#FFFFFF0D] rounded-[5px] p-4 text-[16px] text-white placeholder-font-light ${
            displayInvalidUi ? 'border-red-900' : 'border-[#4D5457]'
          }`}
          placeholder={action === 'create' ? 'Wishist name' : 'New name'}
          autoFocus={true}
        />

        <div className="flex flex-row items-center justify-end gap-4 md:gap-8 mt-8">
          <button className="text-[#7F898D]" onClick={() => onClose()}>
            Cancel
          </button>
          <button
            onClick={(e) => {
              onSubmit();
              e.preventDefault();
            }}
            className={
              'bg-a-blue hover:bg-a-light-blue text-white text-[12px] md:text-[14px] max-w-[156px] h-[54px] rounded-[5px] py-2 px-3'
            }>
            {buttonText && buttonText}
            {!buttonText && action === 'create' && 'Add to New Wishlist'}
            {!buttonText && action === 'update' && 'Rename Wishlist'}
          </button>
        </div>
      </div>
      {busyLoading && <Loader />}
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.auth.user
});
export default connect(mapStateToProps)(CreateOrUpdateWishlistForm);
